/**
 * This route is dynamically added to the app if this section is
 * enabled inside the config for the tenant being built.
 */
// @ngInject
function states($stateProvider) {
  $stateProvider.state('rent-track', {
    parent: 'root',
    url: '/rent-track',
    templateUrl: 'apps/rent-track/templates/acp-rent-track.ng.html',
    data: {
      permissions: {
        only: 'rent_track_enabled',
        redirectTo: {
          rent_track_enabled: 'dashboard'
        }
      }
    },
    resolve: {
      module: /* @ngInject */ function($ocLazyLoad) {
        return import(/* webpackChunkName: "acp.apps.rent-track" */ 'apps/rent-track').then(
          $ocLazyLoad.loadModule
        );
      },
      $componentName: /* @ngInject */ function() {
        return 'rent-track';
      }
    }
  });
}

export default states;
